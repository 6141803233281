import LandingFilter from '@Components/LandingFilter';
import Pagination from '@Components/Pagination';
import ProductCard from '@Components/ProductCard';
import ProductCardLoader from '@Components/ProductCard/ProductCard.loader';
import SearchBar from '@Components/SearchBar';
import Tag from '@Components/Tag';
import { useAuth } from '@Contexts/AuthContext/Auth.context';
import { _GetCategories } from '@Services/Categories';
import { _GetProducts } from '@Services/Products';
import { K_AllProducts, K_Categories } from '@Utils/ReactQuery.keys';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useRouter } from 'next/router';
import More from 'public/show-arrow.svg';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useMedia } from 'react-use';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import {
  CardList,
  FilterContent,
  LandingFiltersWrapper,
  Main,
  PaginationWrapper,
  ShowMore,
  ShowMoreArrow,
  TagList,
  TwoPartsTitle,
} from './FilterSection.styled';

type Props = {};

export default function FilterSection({}: Props) {
  const { t } = useTranslation('Landing');
  const { locale } = useRouter();
  const [category_id, setSelectedCategory] = useState<number>();
  const [offset, setOffset] = useState<number>();
  const [current, setCurrent] = useState<number>(1);
  const { isTokenValid } = useAuth();
  const isMobile = useMedia('(max-width: 576px)');
  const { data: categories } = useQuery(
    K_Categories({
      has_offer: true,
      locale,
    }),
    () =>
      _GetCategories({
        has_offer: true,
      }),
    {
      enabled: isTokenValid,
    }
  );
  const specialProductsKeys = useMemo(
    () => ({
      special_price__isnull: false,
      limit: isMobile ? 2 : 3,
      category_id,
      offset,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [category_id, offset, isMobile]
  );
  const { data, isLoading } = useQuery(
    K_AllProducts({ ...specialProductsKeys, locale }),
    () => _GetProducts(specialProductsKeys),
    {
      enabled: isTokenValid,
      onSuccess: (data) => {
        setTotalProducts(data.count);
      },
    }
  );
  const [totalProducts, setTotalProducts] = useState(data?.count);

  return (
    <Main>
      <Container>
        <Row justifyContent="center">
          <Col col={12}>
            <TwoPartsTitle>
              <h4>{t('part-one')}</h4>
              <h4>{t('part-two')}</h4>
            </TwoPartsTitle>
            <SearchBar />
          </Col>
        </Row>
      </Container>
      <LandingFiltersWrapper>
        <Container>
          <Row justifyContent="center">
            <Col col={12} md={4} lg={3}>
              <LandingFilter />
            </Col>
            <Col col={12} md={8} lg={8}>
              <FilterContent>
                <TagList>
                  <Tag
                    isActive={!category_id}
                    name={t('all')}
                    onClick={() => {
                      setSelectedCategory(undefined);
                      setCurrent(1);
                      setOffset(0);
                    }}
                  />
                  {categories?.results.map((cat) => (
                    <Tag
                      key={cat.id + '_SPECIAL_CATEGORY'}
                      isActive={cat.id === category_id}
                      name={cat.name}
                      onClick={() => {
                        setSelectedCategory(cat.id);
                        setCurrent(1);
                        setOffset(0);
                      }}
                    />
                  ))}
                </TagList>
                <CardList>
                  {!isLoading
                    ? data?.results.map((product) => <ProductCard data={product} key={product.id} />)
                    : [...new Array(3)].map((_, i) => <ProductCardLoader key={i + '_Landing_SPECIAL'} />)}
                </CardList>
                {Boolean(totalProducts) && (
                  <PaginationWrapper>
                    <Pagination
                      total={totalProducts}
                      disabled={isLoading}
                      defaultPageSize={3}
                      pageSize={3}
                      defaultCurrent={1}
                      current={current}
                      showPrevNextJumpers={true}
                      showQuickJumper={false}
                      onChange={(pageNum: number) => {
                        setOffset(3 * (pageNum - 1));
                        setCurrent(pageNum);
                      }}
                    />
                    <ShowMore href={`/products` + (category_id ? `?category_id=${category_id}` : '')}>
                      {t('show_more')}
                      <ShowMoreArrow>
                        <Image src={More} width={7.4} height={12} alt="show more arrow" />
                      </ShowMoreArrow>
                    </ShowMore>
                  </PaginationWrapper>
                )}
              </FilterContent>
            </Col>
          </Row>
        </Container>
      </LandingFiltersWrapper>
    </Main>
  );
}
